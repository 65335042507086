import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "../components/Header";
import Footer from "../components/Footer";
import firebase from "firebase";
import ReactGA from "react-ga";

export default function BookingSuccess() {
  const urlString = window.location.href
  const url = new URL(urlString);
  const email = url.searchParams.get("invitee_email");
  const inviteeFullName = url.searchParams.get("invitee_full_name");
  const phoneNumber = url.searchParams.get("answer_1");

  useEffect(() => {
    ReactGA.pageview('/booking-success');
    const bookingUrl = localStorage.getItem('bookingUrl')
    if(bookingUrl !== url.href) {
      addBookingToDb()
    }
    localStorage.setItem('bookingUrl', url.href)
  }, []);

  const addBookingToDb = () => {
    const db = firebase.firestore()
    return db.collection('yogaBooking').add({ email, inviteeFullName, phoneNumber })
  }

  return (
    <div style={{ height: '100vh' }}>
      <main style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
        <CssBaseline />
        <Header />
        <Grid direction="column" style={{ marginTop: 100, marginBottom: 100 }} ustify="center" alignContent="center"
              container>
          <Typography variant="h4" component="h4" style={{ textAlign: 'center' }}>Tusen takk for
            bestilling: <b>{inviteeFullName}</b></Typography>
          <Typography variant="h5" component="h4" style={{ textAlign: 'center', padding: 20 }}>Vi har nå mottatt
            bestillingen og vil kontakte deg på SMS.</Typography>
          <ul>
            <li><p>You will get to your video call on SMS 1 hour before your booking</p></li>
            <li><p>You pay 200 NOK for the first session</p></li>
            <li><p>Wear comfortable clothes, have a bottle of water ready</p></li>
            <li><p>Yoga mat is helpful but not necessary</p></li>
            <li><p>Yoga instructor will ask if you have any health issue before starting</p></li>
          </ul>
        </Grid>
        <Footer />
      </main>
    </div>
  )
}
