import React from "react";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import logoImg from "../assets/images/logo.png";
import Hidden from "@material-ui/core/Hidden";
import AppBarMenu from "./AppBarMenu";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router"
import ReactGa from "react-ga";

export default function Header() {
  const classes = useStyles();
  const pathName = window.location.pathname;

  const changeColor = (path) => {
    if (pathName === path) {
      return 'rgb(0, 12, 38)'
    }
    return 'rgba(0, 12, 38,05)'
  }
  return (
    <AppBar className={classes.appBar} elevation={0} position="relative">
      <Toolbar className={classes.toolbar}>
        <img
          onClick={() => {
            navigate(`/`)
            ReactGa.event({
              category: 'Logo',
              action: `Logo_image_clicked`
            });
          }}
          style={{ width: 80 }}
          src={logoImg}
        />
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <AppBarMenu />
        </Hidden>
        <Hidden only={'xs'}>
          <nav>
            {/*<Link*/}
            {/*  style={{ color: pathName === '/about' ? 'rgba(0, 12, 38, 1)' : 'rgba(0,0,0,0.7)' }}*/}
            {/*  variant="button" color="textPrimary" href="/about" className={classes.link}>*/}
            {/*  Om oss*/}
            {/*</Link>*/}
            {/*<Link*/}
            {/*  style={{ color: pathName === '/contact' ? 'rgba(0, 12, 38, 1)' : 'rgba(0,0,0,0.7)' }}*/}
            {/*  variant="button" color="textPrimary" href="/contact" className={classes.link}>*/}
            {/*  Kontakt*/}
            {/*</Link>*/}
            {/*<Link*/}
            {/*  style={{ color: pathName === '/faq' ? 'rgba(0, 12, 38, 1)' : 'rgba(0,0,0,0.7)' }}*/}
            {/*  variant="button" color="textPrimary" href="#faq" className={classes.link}>*/}
            {/*  FAQ*/}
            {/*</Link>*/}
            <Button
              className={classes.button}
              style={{ marginLeft: 50, textTransform: 'none' }}
              onClick={() => {
                navigate('/signup')
                ReactGa.event({
                  category: 'Header_button',
                  action: `Bli_superhero_button_clicked`
                });
              }}
              disableElevation
              color="primary"
              variant={pathName === '/signup' ? 'contained' : 'outlined'}
            >
              Bli superhelt
            </Button>
          </nav>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

const isMobile = window.innerWidth < 400;
const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(1, 1.5),
    textTransform: 'none'

  },
  toolbar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#F7F7F7',
    // paddingLeft: isMobile ? 0 : 250,
    // paddingRight: isMobile ? 0 : 250
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgb(0, 12, 38)',
      color: '#FFF',
    }
  }
}));
