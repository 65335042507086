import React, { useEffect } from "react";
import { Typography } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import meImg from '../assets/images/me.jpg'
import karanImg from '../assets/images/karan.png'
import linnImg from '../assets/images/linn.png'
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  root: {
    width: 290,
  },
  media: {
    height: 220,
  },
}));

export default function AboutUsScreen() {
  const classes = useStyles();
  useEffect(() => {
    ReactGA.pageview('/about');
  }, [])
  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Typography component="h1" variant="h4" align="center" color="textPrimary">
            Vi ønsker å gi folk en Super dag
          </Typography>
          <br />
          <Typography align="left">
            Hei! Vi er Team Super! Vårt team jobber på spreng for å nå et felles mål - å bruke teknologi for å skape en
            enklere hverdag for hverdagsfolk. Vi er:
          </Typography>
          <Grid style={{ marginTop: 50 }} container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card elevation={0} className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={meImg}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    CTO: RYSZARD RZEPA
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Built Privatmegleren bolig assistant
                    Built Coffeecloud.no
                    Founded - Godterihus.no
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card elevation={0} className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={karanImg}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    CEO: KARAN SHAH
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Business, strategy and product development at DNB
                    Product @ Min Sky, Telenor
                    Founded - Spoor
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid item xs={12} md={4}>
                <Card elevation={0} className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image={linnImg}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      CCO: LINN E. BJERKELI
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      B2c/B2b Market expert for Atea, NorgesGruppen, PA Consulting
                      Business Coach - Askeladden
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ marginTop: 50 }}>
            <Typography align="left">
              I disse tider, ønsker vi å bidra med alt vi kan for å få folk til å hjelpe hverandre og holde samfunnet i
              gang.
            </Typography>
            <Typography align="left">
              Ønsker du å bli med på denne reisen? Send oss en melding her.
            </Typography>
          </div>
        </Container>
      </main>
      <Footer />
    </React.Fragment>
  )
}
