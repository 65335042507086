import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import SelectComponent from "./Select";
import firebase from 'firebase';
import ReactGa from "react-ga";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      defaultCountry: 'NO',
      defaultNationalNumber: '91911222',
      recaptchaParameters: {
        size: 'invisible',
      }
    }
  ]
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUpForm() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '+47',
  });
  const [jobs, setJobs] = React.useState<string[]>([]);

  const classes = useStyles();
  const db = firebase.firestore();

  const onSignUp = async (e) => {
    e.preventDefault()
    try {
      ReactGa.event({
        category: 'Signup_form',
        action: `Submit_form_button_clicked`
      });
      setLoading(true)
      await db.collection('superHero').add({ ...state, jobs })
      setLoading(false)
      setOpen(true)
      setState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      })
      setJobs([])
    } catch (e) {
      setLoading(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setJobs(event.target.value as string[]);
  };

  const handleFormInput = (e, name) => {
    setState({ ...state, [name]: e.target.value })
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate={false} onSubmit={onSignUp}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => handleFormInput(e, 'firstName')}
                value={state.firstName}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Fornavn"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e) => handleFormInput(e, 'lastName')}
                value={state.lastName}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Etternavn"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => handleFormInput(e, 'email')}
                value={state.email}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Epost"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => handleFormInput(e, 'phoneNumber')}
                value={state.phoneNumber}
                variant="outlined"
                required
                fullWidth
                name="Phone"
                label="Telefonnummer slik at folk kan betale deg med Vipps"
                type="text"
                id="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectComponent handleChange={handleChange} jobs={jobs} />
            </Grid>
          </Grid>
          {loading ?
            <CircularProgress /> :
            <Button
              style={{ height: 50 }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send inn
            </Button>
          }
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Takk for din interesse! Vi tar kontakt med deg så fort som mulig.
        </Alert>
      </Snackbar>
    </Container>
  );
}
