import React from 'react';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import IconMenu from '@material-ui/icons/Menu';
import { navigate } from '@reach/router';
import ReactGa from "react-ga";

export default function AppBarMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    ReactGa.event({
      category: 'Header_button',
      action: `Bli_superhero_button_clicked`
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (path) => {
    setAnchorEl(null);
    navigate(path)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <IconMenu />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/*<MenuItem onClick={() => handleClose('/about')}>Om oss</MenuItem>*/}
        {/*<MenuItem onClick={() => handleClose('faq')}>Faq</MenuItem>*/}
        {/*<MenuItem onClick={() => handleClose('contact')}>Kontakt</MenuItem>*/}
        <MenuItem onClick={() => navigateTo('signup')}>Bli superhelt</MenuItem>
      </Menu>
    </div>
  );
}
