import React, { useEffect } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Header from "../components/Header";
import SignUpForm from "../components/SignupForm";
import Typography from "@material-ui/core/Typography";
import Footer from "../components/Footer";
import ReactGA from "react-ga";
import YouTube from 'react-youtube';
import Container from "@material-ui/core/Container";

export default function SignInScreen() {
  const isMobile = window.innerWidth < 400;
  const opts = {
    height: '400',
    width: '100%',
    playerVars: {
      autoplay: 0,
      start: 12,
    },
  };
  useEffect(() => {
    ReactGA.pageview('/signup');
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Header />
        <Typography style={{ marginTop: 50, padding: 10 }} align="center" component="h1" variant="h5">
          Bli en Superhelt
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ textAlign: 'center', fontSize: 16, maxWidth: 550, }} align="center" component="h3"
                      variant="h6">
            Super er en startup, og er på utkikk etter flere superhelter til teamet vårt. Som Superhelt kan du tjene penger når du vil ved å levere varer hjem til folk.
          </Typography>
        </div>
      </Container>
      <Grid direction={isMobile ? 'column' : 'row'} alignItems="center" justify="center" container>
        <div style={{
          // width: isMobile ? '100vw' : '30vw',
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <SignUpForm />
        </div>

        {!isMobile && <div style={{ marginRight: 20, height: 300, width: 1, backgroundColor: '#c1c1c1' }} />}

        <div style={{
          width: isMobile ? '100vw' : '30vw',
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'left',
          padding: 10
        }}>
          <ul>
            <Typography style={{ textAlign: 'left' }} align="center" component="h1" variant="h6">
              Fordeler:
            </Typography>
            <li>
              <Typography component="p">
                Hjelp lokalsamfunnet inkludert de som sitter hjemme og våre eldre.
              </Typography>
            </li><li>
              <Typography component="p">
                Du får direkte betalt av kunden gjennom VIPPS.
              </Typography>
            </li>
            <li>
              <Typography component="p">OnFleet-appen vil hjelpe deg med å ha full kontroll. Velg din tilgjengelighet og aksepter oppdrag enkelt.
                 </Typography>
            </li>
            <li>
              <Typography component="p">Merk: For å kunne være en superhelt kan du ikke å ha noen av symptomene på
                Covid-19. Les mer på FHIs nettsider. </Typography>
            </li>
          </ul>
        </div>
      </Grid>
      <Grid direction="column" alignItems="center" justify="center" container>
        <Typography style={{ marginTop: 100, padding: 10 }} align="center" component="h1" variant="h5">
          Slik fungerer appen
        </Typography>
        <div style={{
          width: isMobile ? '100vw' : '60vw',
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'left',
          padding: 10
        }}>
          <YouTube videoId="lfQsECqzUzM" opts={opts} />
        </div>
      </Grid>
      <div style={{ marginTop: 50 }}>
        <Footer />
      </div>
    </React.Fragment>
  );
}
