import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SuperHeroStepper from "./SuperHeroStepper";
import ReactGa from "react-ga";

export default function SuperHeroDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    ReactGa.event({
      category: 'superHero',
      action: `Book_button_clicked`
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button style={{ height: 50 }} fullWidth variant="contained" color="primary" onClick={handleClickOpen}>
        Bestill
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <SuperHeroStepper handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
