import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

export default function HeroComponent() {
  const classes = useStyles();
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="md">
        <Typography className={classes.title} component="h1" variant="h2" align="left" color="textPrimary"
                    gutterBottom>
          Hei Oslo, vi gjør hverdagen din enklere i disse tider
        </Typography>
        <Typography style={{ fontSize: 30 }} className={classes.title} component="h1" variant="h4" align="left" color="textPrimary"
                    gutterBottom>
          Vi hjelper med innkjøp, henting og levering hjem til deg
        </Typography>
      </Container>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: '#F7F7F7',
    padding: theme.spacing(8, 0, 2),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontFeatureSettings: "pnum",
    fontWeight: 400,
    fontSize: 60,
    color: '#32325d',
    // lineHeight: 45,
  },
}));
