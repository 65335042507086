import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../components/Header";
import Container from "@material-ui/core/Container";
import Footer from "../components/Footer";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightMedium,
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  }),
);

export default function FaqScreen() {
  const classes = useStyles();
  useEffect(() => {
    ReactGA.pageview('/faq');
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      {/*<Header />*/}
      <main>
        <Container  id="faq" className={classes.cardGrid} maxWidth="md">
          <div className={classes.root}>
            <Typography align="center" style={{ fontWeight: 'bold', paddingBottom: 20, fontSize: 26  }} variant="h5">
              FAQ
            </Typography>

            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Hvordan betaler jeg for tjenesten?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  All betaling skjer via Vipps.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Hvordan kommer jeg i kontakt med Superhelten?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Superhelten sender deg en SMS når han/hun starter på oppdraget ditt.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Hvorfor får jeg SMS fra dere med +1 nummer?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Vi jobber med å få et lokalt nummer. SMS leverandøren vår har ikke satt opp for norsk nummer ennå.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Kan jeg bestille varer fra forskjellige butikker?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Det kan du! Hvis du trenger ulike varer samtidig fikser vi det.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Kan jeg bestille som en bedriftskunde?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Vi prøver å hjelpe alle vi kan i disse tider. Send inn bestillingen din så skal vi hjelpe deg.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Hva skjer hvis varen som jeg ønsker er utsolgt?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Superhelten vil prøve å kjøpe den et annet sted og vil sende deg en SMS hvis han/hun ikke får tak i varen.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Hvor mange ting kan jeg bestille i en ordre?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Vi har ikke satt en max-grense, men hvis det blir for mye å handle på en gang får du en SMS fra Superhelten som vil be deg om å prioritere varene.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Hvordan skjer kontaktløs levering?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Vi leverer pakker utenfør døren din og sørger for å unngå unødvendig kontakt. Vi tar ikke
                  betaling kontant og følger FHIs retningslinjer nøye.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Kan dere hente pakker for meg fra Posten?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Ja det er mulig. Du må sende inn fullmakt til Superhelten når du har fått en SMS. Du kan lese mer
                  om <a href="https://www.posten.no/motta/legitimasjon-og-fullmakter">fullmakt på </a>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Kan jeg bli med på Super-teamet?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Ja! Du kan enkelt bli superhelt og få betalt for arbeidet.
                  Hvis du ønsker å bli med på teamet kan du kontakte oss på epost: contact@heysuper.no
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Er det noe aldergrense for å bli superhelt?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Ja, du må være over 18 år.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Kan jeg stille opp med en ny tjeneste?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  Ja helt klart! Hvis du har en ide om en tjeneste vi kan lansere kan du sende oss en epost, så vil vi
                  kontakte deg så fort som mulig.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Container>
      </main>
      {/*<Footer />*/}
    </React.Fragment>
  );
}
