import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useState } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { ThemeProvider } from '@material-ui/core/styles';
import { Router } from "@reach/router"
import firebase from "firebase";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-160821872-1', {
  debug: true,
});

import theme from './theme';
import HomeScreen from "./screens/HomeScreen";
import SignInScreen from "./screens/SignInScreen";
import BookingSuccess from "./screens/BookingSuccess";
import FaqScreen from "./screens/FaqScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import ContactScreen from "./screens/ContactScreen";

const firebaseConfig = {
  apiKey: "AIzaSyAMv2UVcWVNuwOymrSLsyhjRLJMO-ReF9w",
  authDomain: "super-platform-fbf2a.firebaseapp.com",
  databaseURL: "https://super-platform-fbf2a.firebaseio.com",
  projectId: "super-platform-fbf2a",
  storageBucket: "super-platform-fbf2a.appspot.com",
  messagingSenderId: "738750888838",
  appId: "1:738750888838:web:0f435b000ee4cd96f34a07",
  measurementId: "G-9801HSL2LQ"
};

firebase.initializeApp(firebaseConfig);

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <ThemeProvider theme={theme}>
          <Router>
            <HomeScreen path="/" />
            <SignInScreen path="/signup" />
            <BookingSuccess path="/booking-success" />
            <FaqScreen path="/faq" />
            <AboutUsScreen path="/about" />
            <ContactScreen path="/contact" />
          </Router>
        </ThemeProvider>
      </View>
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([
      require('./assets/images/icon.png'),
      require('./assets/images/logo.png'),
      require('./assets/images/splash.png'),
      require('./assets/images/super-s.png'),
    ]),
    Font.loadAsync({
      // This is the font that we are using for our tab bar
      // We include SpaceMono because we use it in HomeScreen.js. Feel free to
      // remove this if you are not using it in your app
      'space-mono': require('./assets/fonts/FiraSans-Bold.ttf'),
    }),
  ]);
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F7F7F7',
  },
});
