import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import hairImg from '../assets/images/hair.jpg';
import cleaningImg from '../assets/images/cleaning.png';
import beautyImg from '../assets/images/skin.jpeg';
import ShowInterestDialogForm from "../components/ShowIntrestDialogForm";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    webkitBoxShadow: '1px 4px 12px 0px rgba(0,0,0,0.1)',
    boxShadow: '1px 4px 12px 0px rgba(0,0,0,0.1)',
    mozBoxShadow: '1px 4px 12px 0px rgba(0,0,0,0.1)',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  labelIcon: {
    fontSize: 12
  }
}));

const Hairdresser = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={hairImg}
          title="Image title"
        />
        <CardContent className={classes.cardContent}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            Frisør
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Vi kommer hjem til deg for å gi deg en personlig tilpasset frisørtjeneste
              </Typography>
            </div>
            <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Vi bruker anerkjente og profesjonelle hårprodukter
              </Typography>
            </div><div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Vi stiller med all utstyr og rydder opp etter jobben
              </Typography>
            </div>
            <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Alt du trenger er en stol, så vi fikser resten
              </Typography>
            </div>
          </div>

        </CardContent>
        <ShowInterestDialogForm title="hairdresser" />
      </Card>
    </Grid>
  )
}
const Cleaning = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={cleaningImg}
          title="Image title"
        />
        <CardContent className={classes.cardContent}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            Rengjøring
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Få ditt hjem rengjort regelmeesig uten å tømme hele lommeboka
              </Typography>
            </div>
            <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Vi kommer når det passer deg og tilpasser rengjøringen etter dine ønsker
              </Typography>
            </div>
            <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Du stiller med utstyr, vi får jobben gjort
              </Typography>
            </div>
          </div>

        </CardContent>
        <ShowInterestDialogForm title="cleaning" />
      </Card>
    </Grid>
  )
}
const Beauty = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={beautyImg}
          title="Image title"
        />
        <CardContent className={classes.cardContent}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            Hudpleie
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Velg mellom ulike tjenester for ansikt-, fot og håndpleie som vi kan utføre i ditt hjem
              </Typography>
            </div>
            <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Vi stiller med profesjonelle produkter og tilpasser behandlingen for deg
              </Typography>
            </div>
            <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
              <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                Flinke fagfolk med lang erfaring fra bransjen
              </Typography>
            </div>
          </div>

        </CardContent>
        <ShowInterestDialogForm title="beauty" />
      </Card>
    </Grid>
  )
}

export default function ComingSoonPackages() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Typography style={{ fontWeight: 'bold', paddingBottom: 20, fontSize: 26 }} variant="h5">
            Hjemmetjenestene som kommer snart
          </Typography>
          <Grid container spacing={4}>
            <Beauty />
            <Hairdresser />
            <Cleaning />
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
