import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ReactGA from 'react-ga';

import Header from "../components/Header";
import HeroComponent from "../components/HeroComponent";
import Footer from "../components/Footer";
import PhysicalPackages from "../components/PhysicalPackages";
import FaqScreen from "./FaqScreen";
import ComingSoonPackages from "./CominSoonPackages";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    // paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}));

export default function HomeScreen() {

  useEffect(() => {
    ReactGA.pageview('/home');
  }, [])

  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Header />
          <HeroComponent />
          <PhysicalPackages />
          <ComingSoonPackages />
          <FaqScreen />
        </Container>
      </main>
      <Footer />
    </React.Fragment>
  );
}
