import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ReactGa from "react-ga";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ShowInterestDialogForm({ title }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [email, setEmail] = useState('')

  const handleClickOpen = () => {
    setOpen(true);
    ReactGa.event({
      category: 'showInterest',
      action: `${title}_button_clicked`
    });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSnack(false)
  };

  const onSubmit = async (e) => {
    e.preventDefault()
    const db = firebase.firestore()
    ReactGa.event({
      category: 'showInterest',
      action: `${title}_email_submitted`
    });
    try {
      setLoading(true)
      await db.collection('likedPackage').add({ email, title })
      setLoading(false)
      setOpenSnack(true)
    } catch (e) {
      setLoading(false)
    }
  }

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button disableRipple style={{ height: 50 }} fullWidth variant="outlined" color="primary"
              onClick={handleClickOpen}>
        Få tidlig tilgang
      </Button>
      <Dialog maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Skriv inn din epost for å melde interesse</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <TextField
              onChange={e => setEmail(e.target.value)}
              required
              autoFocus
              margin="dense"
              id="name"
              label="Epost"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Avbryt
            </Button>
            {
              loading ?
                (<div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </div>
                ) :
                <Button disabled={openSnack} type="submit" variant="contained" color="primary">
                  Send
                </Button>
            }
          </DialogActions>
        </form>
        <Snackbar open={openSnack} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Takk for din interesse - vi vil holde deg oppdatert! Ha en Super dag! 😊
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  );
}
