import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import firebase from "firebase";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReactGa from "react-ga";


function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Beskriv jobb', 'Kontaktinformasjon'];
}

function getStepContent(stepIndex: number, state, setState, setValue, value) {
  switch (stepIndex) {
    case 0:
      return <ChooseTaskForm value={value} setValue={setValue} state={state} setState={setState} />;
    case 1:
      return <ConfirmBookingForm value={value} setValue={setValue} state={state} setState={setState} />;
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown stepIndex';
  }
}

const ChooseTaskForm = ({ state, setState, setValue, value }) => {

  const onChange = (e, name) => {
    setState({ ...state, [name]: e.target.value })
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography style={{ paddingBottom: 0, fontWeight: 'bold' }} component="p">
        Beskriv så godt som mulig hva du trenger
      </Typography>
      <TextField
        rows={3}
        multiline
        onChange={e => onChange(e, 'task')}
        value={state.task}
        required
        fullWidth
        id="outlined-basic"
        label="f.eks. 2 pakker bleier av typen Pampers str 4, 1 stk øretermometer fra apoteket."
        variant="outlined"
      />
      <Typography style={{ paddingTop: 30, fontWeight: 'bold' }} component="p">
        Når vil du ha leveransen?
      </Typography>
      <Typography style={{ paddingBottom: 10, fontSize: 12 }} component="p">
        Obs. Bruk alternativet "fortest mulig" for presserende forespørsler for å sikre at vi prioriterer riktig
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
          <div>
            <FormControlLabel value="Raskest mulig" control={<Radio />} label="Fortest mulig" />
            {/*<FormControlLabel value="Innen 3 timer" control={<Radio />} label="Innen 3 timer" />*/}
            <FormControlLabel value="Løpet av dagen" control={<Radio />} label="I løpet av dagen" />
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  )
}

const ConfirmBookingForm = ({ state, setState }) => {
  const isMobile = window.innerWidth < 400;

  const onChange = (e, name) => {
    setState({ ...state, [name]: e.target.value })
  };

  return (
    <div style={{ marginBottom: 20, marginTop: 20, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
      <div style={{ width: isMobile ? '100%' : '50%' }}>
        <Typography style={{ paddingBottom: 20 }} component="p">
          Fyll ut detaljene
        </Typography>
        <TextField
          onChange={e => onChange(e, 'name')}
          value={state.name}
          required
          fullWidth
          id="outlined-basic"
          label="Skriv inn navnet ditt"
          variant="outlined"
        />
        <div style={{ padding: 10 }} />
        <TextField
          onChange={e => onChange(e, 'phoneNumber')}
          value={state.phoneNumber}
          required
          fullWidth
          id="outlined-basic"
          label="Skriv inn telefonnummeret ditt"
          variant="outlined"
        />
        <div style={{ padding: 10 }} />
        <TextField
          onChange={e => onChange(e, 'address')}
          value={state.address}
          required
          fullWidth
          id="outlined-basic"
          label="Til hvilken adresse vil du ha tjenesten levert?"
          variant="outlined"
        />
      </div>
      <div style={{ width: isMobile ? '100%' : '50%', paddingLeft: isMobile ? 0 : 20 }}>
        <ul style={{ padding: 18 }}>
          <li>Når du har sendt inn din bestilling får du en SMS når superhelten din er på vei.</li>
          <li>Du vippser totalbeløpet (varer + levering) etter at varene er levert</li>
          <li>Det koster deg 79 NOK for levering</li>
          <li>Du vil få en kvittering for kjøpet</li>
        </ul>
      </div>
    </div>
  )
}

export default function SuperHeroStepper(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState('Innen 3 timer');

  const steps = getSteps();

  const onBookingConfirm = async () => {
    const db = firebase.firestore();
    try {
      ReactGa.event({
        category: 'superHero',
        action: `Complete_booking_button_clicked`
      });
      if (!state.name || !state.phoneNumber || !state.address) {
        return alert('Vennligst skriv inn ditt navn, telefonnummer og adresse')
      }
      setLoading(true)
      await db.collection('superHeroRequest').add(state)
      setLoading(false)
      setOpen(true)
      setTimeout(() => {
        props.handleClose()
      }, 3000)
      setState({})
    } catch (e) {
      setLoading(false)
      props.handleClose()
    }
  };

  const handleNext = () => {
    if (!state.task) {
      return alert('Vennligst skriv det du trenger først')
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    ReactGa.event({
      category: 'superHero',
      action: `Next_button_clicked`
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const ConfirmOrderButton = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )
    }
    return (
      <Button variant="contained" color="primary" onClick={onBookingConfirm}>
        Bekreft
      </Button>
    )
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <Typography
            className={classes.instructions}>{getStepContent(activeStep, state, setState, setValue, value)}</Typography>
          <div style={{ marginTop: 40 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              Tilbake
            </Button>
            {
              activeStep === 0 ?
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Neste
                </Button> :
                <ConfirmOrderButton />
            }
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Takk for bestillingen! Du får en SMS om kort tid. Ha en Super dag! 😊
        </Alert>
      </Snackbar>
    </div>
  );
}
