import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from "firebase";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ReactGA from "react-ga";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ContactScreen() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState({
    email: '',
    message: ''
  })

  useEffect(() => {
    ReactGA.pageview('/contact');
  }, [])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const db = firebase.firestore()
      await db.collection('contactMessage').add(state)
      setLoading(false)
      setOpen(true);
      setState({
        email: '',
        message: ''
      })
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Typography component="h1" variant="h4" align="center" color="textPrimary">
            Vi vil hjelper folk med å bygge en enklere hverdag
          </Typography>
          <br />
          <Typography align="center">
            Har du noen ideer, tilbakemelding eller ønske å hjelpe oss med noe, så send oss gjerne en melding.
          </Typography>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <form onSubmit={sendMessage} className={classes.form} noValidate={false}>
                <TextField
                  onChange={e => setState({ ...state, email: e.target.value })}
                  value={state.email}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Din e-post"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  onChange={e => setState({ ...state, message: e.target.value })}
                  value={state.message}
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="Melding"
                  label="Melding"
                  type="text"
                />
                {loading ?
                  (<div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </div>) :
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Send
                  </Button>
                }
              </form>
            </div>
          </Container>
        </Container>
      </main>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Takk for tilbakemelding!
        </Alert>
      </Snackbar>
      <Footer />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 50
  },
}));
