import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import superImg from '../assets/images/superHero.jpg';
import dogImg from '../assets/images/dog.jpg';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import SuperHeroDialog from "./SuperHeroDialog";
import ShowInterestDialogForm from "./ShowIntrestDialogForm";
import ItemsCarousel from 'react-items-carousel';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    webkitBoxShadow: '1px 4px 12px 0px rgba(0,0,0,0.1)',
    boxShadow: '1px 4px 12px 0px rgba(0,0,0,0.1)',
    mozBoxShadow: '1px 4px 12px 0px rgba(0,0,0,0.1)',
    backgroundColor: '#fff'
  },
  cardMedia: {
    paddingTop: '46.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: '#fff'
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function PhysicalPackages() {

  const classes = useStyles();
  return (
    <React.Fragment>
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          <Typography style={{ fontWeight: 'bold', paddingBottom: 20, fontSize: 26  }} variant="h5">
            Hjemmetjenester
          </Typography>
          <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={superImg}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography align="center" gutterBottom variant="h6" component="h2">
                      Få ting levert samme dag
                    </Typography>

                    <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CheckCircleOutlineOutlinedIcon fontSize="small" />
                        <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                          Trenger du noe fra apoteket? Kanskje noen bleier fra butikken?
                        </Typography>
                      </div>
                      <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
                        <CheckCircleOutlineOutlinedIcon fontSize="small" />
                        <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                          Våre superhelter hjelper deg med innkjøp, henting og levering.
                        </Typography>
                      </div>
                      <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
                        <CheckCircleOutlineOutlinedIcon fontSize="small" />
                        <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                          Tilgjengelig fra kl 10 - 20 og vi levere så raskt som mulig innen samme dag
                        </Typography>
                      </div>
                      <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>
                        <CheckCircleOutlineOutlinedIcon fontSize="small" />
                        <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">
                          Vi tilbyr "null-kontakt" levering og betaling for å hindre smitte
                        </Typography>
                      </div>
                      <Typography align="center" style={{ padding: 15, fontSize: 20, fontWeight: 'bold' }} component="p">
                        79 NOK
                      </Typography>
                    </div>

                  </CardContent>
                  <SuperHeroDialog />
                </Card>
              </Grid>
              {/*<Grid item xs={12} md={6}>*/}
              {/*  <Card className={classes.card}>*/}
              {/*    <CardMedia*/}
              {/*      className={classes.cardMedia}*/}
              {/*      image={dogImg}*/}
              {/*      title="Image title"*/}
              {/*    />*/}
              {/*    <CardContent className={classes.cardContent}>*/}
              {/*      <Typography align="center" gutterBottom variant="h6" component="h2">*/}
              {/*        Privat hundelufting*/}
              {/*      </Typography>*/}

              {/*      <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>*/}
              {/*        <div style={{ display: 'flex', alignItems: 'center' }}>*/}
              {/*          <CheckCircleOutlineOutlinedIcon fontSize="small" />*/}
              {/*          <Typography align="left" style={{ paddingLeft: 10, fontSize: 14 }} component="p">*/}
              {/*            Sitter fast i karantene? Trenger hunden din litt luft?*/}
              {/*          </Typography>*/}
              {/*        </div>*/}
              {/*        <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>*/}
              {/*          <CheckCircleOutlineOutlinedIcon fontSize="small" />*/}
              {/*          <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">*/}
              {/*            Du får bilder og live oppdatering på GPS*/}
              {/*          </Typography>*/}
              {/*        </div>*/}
              {/*        <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>*/}
              {/*          <CheckCircleOutlineOutlinedIcon fontSize="small" />*/}
              {/*          <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">*/}
              {/*            Tilgjengelig fra 10 - 20 med samme dag booking*/}
              {/*          </Typography>*/}
              {/*        </div>*/}
              {/*        <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center' }}>*/}
              {/*          <CheckCircleOutlineOutlinedIcon fontSize="small" />*/}
              {/*          <Typography style={{ paddingLeft: 10, fontSize: 14 }} component="p">*/}
              {/*            Null kontakt henting og levering*/}
              {/*          </Typography>*/}
              {/*        </div>*/}
              {/*      </div>*/}

              {/*    </CardContent>*/}
              {/*    <ShowInterestDialogForm title="hundelufting" />*/}
              {/*  </Card>*/}
              {/*</Grid>*/}

          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
