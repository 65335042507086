import React, { useState } from "react";
import logoImg from "../assets/images/whiteLogo.png";
import Typography from "@material-ui/core/Typography";
import Copyright from "./Copyright";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from "@material-ui/core";
import firebase from "firebase";
import Container from '@material-ui/core/Container';

export default function Footer() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const onEmailSend = async (e) => {
    e.preventDefault()
    const db = firebase.firestore();
    try {
      setLoading(true)
      await db.collection('email').add({ email })
      setLoading(false)
      setSent(true)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <footer className={classes.footer}>
      <Container style={{ paddingBottom: 10 }} maxWidth="md">
        <Typography style={{ color: '#fff' }} variant="h4" align="center" component="h5">
          Om oss
        </Typography>
        <p style={{ color: '#fff', textAlign: 'center' }}>
          Hei! Vi er Super, en startup som ble født av nødvendighet i denne vanvittige tiden med korona. Vi ønsker å være en one-stop shop for alle dine daglige behov, men det vil ta oss litt tid å komme dit.
          Akkurat nå har vi startet med bare en tjeneste som vi tror er viktig i disse tider, nemlig hjemmelevering.
          Hjemmelevering av nødvendige varer fra butikker og apotek for de som ikke vil risikere å gå ut selv.
        </p>
      </Container>
      <div style={{ margin: 20 }}>
        <Typography style={{ color: '#fff' }} variant="h4" align="center" component="h5">
          Vil du vite mer om Super?
        </Typography>
        {sent ?
          <Typography style={{ color: '#fff' }} align="center" component="p">
            Takk skal du ha! Innsendingen din er mottatt!
          </Typography> :
          <form onSubmit={onEmailSend} noValidate={false} autoComplete="off">
            <div style={{
              height: 60,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              marginTop: 20,
              marginBottom: 50
            }}>
              <TextField
                required
                type="email"
                onChange={e => setEmail(e.target.value)}
                style={{ borderWidth: 0, height: 60, color: '#fff', backgroundColor: '#fff' }}
                id="outlined-basic"
                label="Skriv inn din email"
                variant="filled"
                className={classes.textField}
              />
              {loading ?
                <CircularProgress style={{ color: '#fff' }} /> :
                <Button
                  type="submit"
                  style={{ color: '#fff', borderRadius: 0, width: 100, backgroundColor: '#ff2e68', height: '100%' }}>
                  Send
                </Button>
              }

            </div>
          </form>
        }
      </div>
      {/*<div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: 10 }}>*/}
      {/*  <img style={{ width: 80, height: 25 }} src={logoImg} />*/}
      {/*</div>*/}
      <p style={{ color: '#fff', textAlign: 'center' }}>contact@heysuper.no</p>
      <Typography style={{ color: '#fff' }} variant="subtitle1" align="center" color="textSecondary" component="p">
        Ved å bruke dette nettstedet godtar du <a style={{ color: '#fff' }}
                                                  href={'https://docs.google.com/document/d/12i32uBSSAB0bgjTyQg5ezuKcBFmYiRblbrg475JGHTA/edit'}>vilkårene</a> for
        bruk.
        Se vår <a style={{ color: '#fff' }}
                  href={'https://docs.google.com/document/d/1v-AfZjjtfH1AUWOZ5Y-y5jmpGgRpqDQluxj4m-qKXbM/edit'}>personvernpolicy</a>
      </Typography>
    </footer>
  )
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: 'rgb(0, 12, 38)',
    padding: theme.spacing(6),
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  }
}));
